.scrollbar {
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
    height: 95%;
    overflow-x: hidden;
}

#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
    border-radius: 10px;
}

#style-4::-webkit-scrollbar-thumb {
    background-color: #FFCE4F;
    border: 2px solid #FFCE4F;
}

.no-scroll {
    overflow: hidden;
    height: 100%;
}

#style-option::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #000000;
}

#style-option::-webkit-scrollbar {
    width: 2px;
    background-color: #000000;
    border-radius: 10px;
}

#style-option::-webkit-scrollbar-thumb {
    background-color: #5bbf69;
    border: 2px solid #5bbf69;
}
