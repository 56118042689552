body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.banner-bg {
    background-image: url("https://abhhyamsecure.com/assets/img/banner/banner_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #0a0a13;
    z-index: 0;
}

@media (max-width: 768px) {
    .banner-bg {
        background-image: none;
    }
}

.about_banner_bg {
    background-image: url("https://abhhyamsecure.com/assets/img/bg/breadcrumb_bg.jpg");
}

.about_banner_bg {
    background-image: url("https://abhhyamsecure.com/assets/img/bg/breadcrumb_bg.jpg");
}

.sidebar_head_contact {
    background-image: url("https://abhhyamsecure.com/assets/img/services/sidebar_contact_bg.jpg");
}

.textPlaceholder::placeholder {
    color: #14176c !important;
    font-weight: 500;
}

.success_message {
    color: #279127;
    background-color: #bbffbb;
    font-weight: bold;
    line-height: 1.2;
    border-radius: 4px;
    padding: 10px;
}

.error_message {
    color: #bb0000;
    background-color: #ffcbcb;
    font-weight: bold;
    line-height: 1.2;
    border-radius: 4px;
    padding: 10px;
}

.registerBtn {
    background: #fb4343;
    width: 100%;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    border-width: 0;
    font-weight: bold;
}

.loginBtn {
    background: #5bbf69;
    width: 100%;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    border-width: 0;
    font-weight: bold;
}

.registerBtn:hover {
    background: #000000;
    color: #ffffff;
    box-shadow: 8px 9px #00000033;
}

.loginBtn:hover {
    background: #000000;
    color: #ffffff;
    box-shadow: 8px 9px #00000033;
}

figure img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.css-13cymwt-control, .css-t3ipsp-control {
    min-height: 50px !important;
    background-color: rgb(255, 215, 136) !important;
    border-color: white !important;
    box-shadow: unset !important;
}

.css-t3ipsp-control:hover {
    border-color: white !important;
    box-shadow: unset !important;
}

.css-tj5bde-Svg {
    fill: #14176c !important;
    stroke: #14176c !important;
}

.css-1jqq78o-placeholder, .css-1dimb5e-singleValue {
    color: #14176c !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.css-1xc3v61-indicatorContainer svg {
    height: 17px !important;
    width: 17px !important;
    font-weight: 500 !important;
    text-align: end !important;
}

.css-1xc3v61-indicatorContainer {
    padding: 0 !important;
}

.css-1u9des2-indicatorSeparator {
    background-color: rgb(255, 215, 136) !important;
}

.css-1jqq78o-placeholder {
    text-indent: 5px;
}

.policyHeading {
    font-size: 1.5rem;
}

.registrationHeading {
    font-style: italic;
    font-size: 35px !important;
}

.registrationSubHeading {
    font-style: italic;
    font-size: 16px !important;
    font-weight: bold;
}

@media (max-width: 767.98px) {
    .policyHeading {
        font-size: 1.2rem;
        text-align: center;
    }

    .registrationHeading {
        font-style: italic;
        font-size: 30px !important;
        text-align: left !important;
    }

    .contentHeading{
        font-size: 16px !important;
    }
}

.optionsBg {
    background-color: #ffd788;
}

.optionsText {
    color: #14176c !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.optionsText:hover {
    background-color: #5bbf69;
    cursor: pointer;
}

.offerSidebarActive{
    background-color: #0e104b !important;
    color: #fff !important;
    border-color: #0e104b !important;
}

li, p{
    word-break: break-word;
}

/* Figure tab me jo table hai use responsive banane ke liye */
figure.table {
    width: 100%;
    overflow-x: auto; /* Scroll enable karega */
    display: block;
}

/* Table ka styling */
figure.table table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    min-width: 600px; /* Table ko scrollable banane ke liye */
}

figure.table th{
    text-align: left;
}

 figure.table td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    white-space: nowrap; /* Column wrap na ho */
}

figure.table thead {
    background-color: #007bff;
    color: #ffffff;
}

figure.table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

figure.table tbody tr:hover {
    background-color: #e9ecef;
}

/* Mobile view ke liye scroll enable */
@media screen and (max-width: 768px) {
    figure.table {
        overflow-x: auto;
    }

    figure.table table {
        width: 100%;
        display: block;
        overflow-x: auto;
    }
}

.contentHeading{
    font-size: 20px;
}
