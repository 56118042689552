.faq-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.faq-question {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
}

.faq-answer {
    margin-top: 8px;
    color: #666;
    display: none;
    font-weight: 500;
}

.faq-item.open .faq-answer {
    display: block;
}
